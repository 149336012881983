@import "../../styles/colors";

.toolspage {
  margin-top: 40px;
  min-height: 70vh;
  .category {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .topic {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    a {
      padding: 8px 0;
      font-size: 20px;
      color: $deep-sea-blue;
    }
  }
}

.pathspage {
  min-height: 70vh;
  h1 {
    text-align: center;
    color: $greyish-brown;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orgnewspage {
  margin-bottom: 50px;
  min-height: 70vh;
  .startcontainer {
    padding: 5px;
    color: $greyish-brown;
    img {
      margin: 20px 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .noenrollment {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .text {
        .startheader {
          margin-bottom: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;
        font-size: 16px;
        line-height: 20px;
      }
      a {
        color: $deep-sea-blue;
        font-weight: bold;
      }
      button {
        margin-bottom: 30px;
      }
    }
  }
  .markdownbox {
    flex: 1 1 45%;
    margin: 0 10px;
    h1 {
      color: $deep-sea-blue;
      font-weight: bold;
    }
    .markdown {
      border-radius: 3px;
      background-color: #f1fafa;
      padding: 20px;
      margin-top: 10px;
      min-height: 300px;
      font-size: 16px;
      a {
        color: $deep-sea-blue;
      }
    }
  }
  .news {
    min-width: 420px;
    flex: 1 1 90%;
  }
  .eventsimpactcontainer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .events {
      min-width: 420px;
    }
    .impact {
      min-width: 420px;
    }
  }
}

.react-tabs {
  &__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    font-weight: bold;
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0px 12px 4px 12px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin-bottom: 4px;
    font-size: 12px;

    &--selected {
      background: #fff;
      border-color: #aaa;
      color: black;
      border-bottom: 2px solid black;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.auditpathhelp {
  width: 100%;
  padding: 30px;
}
