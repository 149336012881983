.development-item-card-frame {
  border: 1px solid #bae9e8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: hidden;

  .colorband {
    width: 10px;
    min-width: 10px;
  }

  .colorband-passive {
    background-color: #f2f2f2;
  }

  .colorband-notstarted {
    background-color: #e8f6f6;
  }

  .colorband-ongoing {
    background-color: #bae9e8;
  }

  .colorband-ready {
    background-color: #00b7c6;
  }
}
