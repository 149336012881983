@import "./../../../styles/colors";
.attachment-field {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  .attachment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .header {
      z-index: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      border-radius: 3px;
      border: 1px solid transparent;
      transition: 0.3s;
      padding-left: 5px;
      padding-right: 0;
      .title {
        line-height: 30px;
        color: #1e9ea8;
        font-weight: bold;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .hoverborder {
      cursor: pointer;
      &:hover {
        border: 1px solid lightgrey;
      }
    }
    .attachment-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .attachment {
        border: 1px solid transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 5px;
        .name {
          color: #1e9ea8;
        }
        span {
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }
}

.add-attachments {
  height: 28px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0;
  span {
    color: $deep-sea-blue;
    font-weight: bold;
  }
  img {
    width: 30px;
    height: 30px;
  }
  padding-left: 23px;
  transition: 0.3s;
  border: 1px solid transparent;
  border-radius: 3px;
}
.noleftpad {
  .header {
    padding-left: 0 !important;
  }
  .attachment-list {
    .attachment {
      padding-left: 0 !important;
    }
  }
}
.attachment-modal.unlink-modal {
  .modal-content {
    .modal-body {
      min-height: 190px;
      h1 {
        margin-bottom: 15px;
      }
    }
    min-height: 300px;
    height: 100%;
  }
}
