@import "../../styles/colors";

.signoutcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    color: $deep-sea-blue;
    margin: 0 10px;
    text-align: center;
  }
}
