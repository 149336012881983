@import "../../../styles/colors";
@import "../../../styles/mixins";

.toolLinks {
  width: 100%;
  border-left: 1px solid #dff4fa;
  border-right: 1px solid #dff4fa;
  border-bottom: 1px solid #dff4fa;
  padding: 20px 43px;
  @include screen-xs {
    padding: 20px 13px;
  }
  @include screen-sm {
    padding: 20px 13px;
  }
  .toolsHeader {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    img {
      height: 24px;
      width: 24px;
      margin-right: 21px;
    }
    b {
      font-size: 19px;
    }
  }
  .links {
    margin-top: 20px;
    padding-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    a {
      min-width: 200px;
      color: $deep-sea-blue;
      font-size: 16px;
      margin: 3px 0 3px 0;
    }
  }
}
