@import "../../styles/vendor/bootstrap/variables";
@import "../../styles/colors";
@import "../../styles/mixins";

// TODO: Clean navigation css at some point.
.tahtiseuranav {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  z-index: 500;
  margin-bottom: 0px;
  box-shadow: 0px 1px 5px 0px rgba(130, 130, 130, 1);
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .innernavcontainer {
      width: 100%;
    }
    width: 100%;
    max-height: 50px;
    padding-right: 0;
    .navbar-header {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .navbar-toggle {
        position: absolute;
        right: 0;
      }
    }
    .servicenotificationcontainer {
      width: 100%;
    }
  }
  .logo {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 3px;
      margin-right: 50px;
      font-size: 18px;
      color: $deep-sea-blue;
      font-weight: 600;
    }
  }

  .navbar-collapse {
    max-height: 53px;
    .navbar-nav {
      .dropdown {
        height: 50px;
      }
    }
  }
  .navbar-right {
    min-height: 50px !important;
    margin: 0;
    float: right;
  }
  .dropdown {
    min-height: inherit;
    .dropdown-menu {
      float: right;
      padding: 0;
      li {
        border-bottom: 1px solid $deep-sea-blue;
        border-left: 1px solid $deep-sea-blue;
        border-right: 1px solid $deep-sea-blue;
        a {
          color: $deep-sea-blue;
          padding: 10px 20px;
          text-align: center;
          width: inherit;
          height: inherit;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 250px;
          min-height: 60px;
          overflow: visible;
          span {
            white-space: normal;
            width: 100%;
          }
          .federationname {
            color: #4a4a4a;
            font-size: 12px;
          }
        }
      }
    }
  }
  .dropdown-toggle {
    border: none;
    height: 100%;
    min-height: inherit;
    font-weight: normal;
    color: $deep-sea-blue !important;
    .caret {
      display: none;
    }
    padding: 0 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    .navbar-collapse {
      padding: 0 40px 10px 15px;
      max-height: initial;
    }
    .navbar-right {
      width: 100%;
      border: 1px solid $deep-sea-blue;
      box-shadow: 0px 2px 5px 0px rgba(130, 130, 130, 1);
    }
    .navigationlink {
      height: 60px;
      border-left: 1px solid $deep-sea-blue;
      border-right: 1px solid $deep-sea-blue;
      a {
        padding: 20px 5px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border: none !important;
      }
      text-align: center;
    }
    .navbar-nav {
      margin: 0;
      .dropdown {
        padding: 0 0;
      }
    }
    .dropdown {
      width: 100%;
      display: table;
    }
    .dropdown-toggle {
      display: none;
    }
    .dropdown-menu {
      display: block;
      position: static;
      width: 100%;
      margin-top: 0;
      box-shadow: none;
      border: none;
      li {
        border-bottom: 1px solid $deep-sea-blue;
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
  .navdropdownbutton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .currentclub {
      color: #4a4a4a;
      text-align: center;
      font-size: 12px;
      line-height: initial;
    }
    .texts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.navigationlink {
  background-color: white;
  transition: 0.5s;
  height: 50px;
  a {
    transition: 0.5s;
    height: 41px;
    padding-bottom: 1px !important;
    margin-bottom: 10px;
    color: $deep-sea-blue !important;
    border-bottom: 2px solid white;
  }
  &.active {
    a {
      background-color: inherit !important;
      border-bottom: 2px solid $deep-sea-blue;
    }
  }
  &:hover {
    background-color: #f9f9f9;
    a {
      border-bottom: 2px solid #f9f9f9;
    }
    &.active {
      a {
        border-bottom: 2px solid $deep-sea-blue;
      }
    }
  }
}

.navidisabled {
  cursor: not-allowed;
  a {
    color: $gray-dark !important;
    cursor: not-allowed;
  }
}

.logoutlink {
  a {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    .logoutfirstname {
      margin-right: 10px;
    }
  }
}

.languagedropdown {
  padding-left: 10px;
}
