@import "../styles/colors";

.alertcontainer {
  &.errortemplate {
    color: $lipstick;
    font-size: 19px;
    font-weight: 600;

    .errors {
      display: flex;
      flex-direction: column;

      span:not(:last-of-type) {
        margin-bottom: 5px;
      }

      span:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  .title {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
    font-size: 18px;

    button {
      text-transform: none;
      padding: 11px 20px;
    }
  }
}
