.development-item-summary-card {
  .left-content {
    display: flex;
    flex-direction: column;
    width: 55%;

    > * {
      margin: 10px 20px 10px 20px;
    }

    .description {
      margin-top: auto;
      margin-bottom: auto;
      font-weight: bold;

      text-overflow: ellipsis;
      max-height: 100%;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .year {
      color: #808080;
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-evenly;

    > * {
      margin: 0 10px 0 10px;
    }

    .inforow {
      display: flex;
      flex-direction: row;

      .status {
        width: 100%;
        display: flex;
        flex-direction: row;

        .statusicon {
          margin-right: 8px;
        }

        .statusdesc {
          align-self: center;
          flex-grow: 1;
        }
      }

      .statusrow {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-shrink: 0;

        .importance {
          font-size: 11px;
          font-weight: bold;
          color: #29a1d5;
          margin-top: 2px;
          margin-right: 5px;
        }

        img {
          margin-left: 6px;
        }
      }
    }

    .personrow {
      width: 100%;
      display: flex;
      flex-direction: row;

      .personicon {
        margin-right: 8px;
      }
      .not-assigned {
        opacity: 0.5;
      }

      .assignee {
        align-self: center;
        flex-grow: 1;

        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }

  .menu-btn {
    margin-right: 5px;
  }
}
