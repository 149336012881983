@import "./vendor/bootstrap/variables";
@import "./colors";

@mixin link($color: $deep-sea-blue, $font-size: 13px) {
  font-size: $font-size;
  letter-spacing: 0.3px;
  color: $color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@mixin title($color: $deep-sea-blue, $font-size: 16px) {
  font-size: $font-size;
  font-weight: 600;
  text-align: left;
  color: $color;
}

@mixin form-field-label(
  $color: $deep-sea-blue,
  $font-size: 16px,
  $text-transform: uppercase
) {
  font-size: $font-size;
  font-weight: 600;
  text-align: left;
  text-transform: $text-transform;
  color: $color;
}

@mixin paragraph($color: $warm-grey, $font-size: 13px, $text-align: initial) {
  font-size: $font-size;
  font-style: normal;
  letter-spacing: 0.3px;
  color: $color;
  text-align: $text-align;
}

@mixin paragraph-italic($color: $warm-grey, $font-size: 13px) {
  font-size: $font-size;
  font-style: italic;
  letter-spacing: 0.3px;
  color: $color;
}

@mixin placeholder($color, $font-weight, $font-style, $font-size) {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size !important;
    letter-spacing: 0.3px;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size !important;
    letter-spacing: 0.3px;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size !important;
    letter-spacing: 0.3px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color !important;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size !important;
    letter-spacing: 0.3px;
  }

  &::-ms-input-placeholder {
    /* Edge */
    color: $color !important;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size !important;
    letter-spacing: 0.3px;
  }
}

/**
* Mixin media queries for responsive content
**/

// - 320px
@mixin screen-xs-sm {
  @media (max-width: 320px) {
    @content;
  }
}

// - 480px
@mixin screen-xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// 481px - 768px
@mixin screen-sm {
  @media (min-width: #{$screen-xs + 1}) and (max-width: #{$screen-sm}) {
    @content;
  }
}

// - 768px
@mixin screen-sm-max {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// 769px - 992px
@mixin screen-md {
  @media (min-width: #{$screen-sm + 1}) and (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin screen-md-min {
  @media (min-width: #{$screen-sm + 1}) {
    @content;
  }
}

// 993px - 1200px
@mixin screen-lg {
  @media (min-width: #{$screen-md + 1}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

// 1201px -
@mixin screen-xl {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// 1201px -
@mixin screen-xl2 {
  @media (min-width: 1850px) {
    @content;
  }
}

// Vertical height max 500px
@mixin screen-xs-vertical {
  @media (max-height: 500px) {
    @content;
  }
}

@mixin high-dpi {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 120dpi) {
    @content;
  }
}
