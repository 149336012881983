.development-item-list-container {
  min-width: 300px;
  margin-right: 35px;
  display: grid;
  overflow-y: auto;

  .fade {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 1;
    z-index: 2;
  }

  .development-item-list {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .addnewitem {
      background: white;
    }

    > * {
      margin-left: 10px;
    }
  }

  .development-item-list-dragover {
    background-color: #00b7c6;

    h3 {
      color: white;
    }
  }

  .development-item-list-droppable {
    background-color: #bae9e8;
  }
}
