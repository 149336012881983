@import "../../styles/colors";
@import "../../styles/mixins";

.homepublic {
  color: $greyish-brown;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .topic {
    margin-top: 40px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 36px;
    letter-spacing: 1px;
    width: 100%;
  }
  .subtopic {
    text-overflow: ellipsis;
    text-align: center;
    max-width: 800px;
    font-size: 16px;
    line-height: 1.38;
    color: $homesubgrey;
    margin-bottom: 10px;
  }
  .kpicontainer {
    width: 100%;

    .bigkpi {
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      width: 100%;
      margin-top: 20px;

      .boxwrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .number {
          text-align: center;
          padding: 10px;
          .amount {
            font-size: 64px;
            color: $deep-sea-blue;
            font-weight: bold;
          }
          .text {
            font-size: 28px;
            font-weight: bold;
          }
        }
      }
    }
    .smallkpi {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .box {
        padding: 15px 10px 15px 10px;
        background-color: $pale-grey;
        margin: 10px;
        flex: 0 0 30%;
        min-height: 160px;
        max-height: 190px;
        min-width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        .title {
          opacity: 1;
          padding-top: 10px;
          min-height: 100px;
          font-weight: bold;
          height: 50%;
          font-size: 20px;
          padding-bottom: 10px;
          width: 100%;
        }
        .number {
          opacity: 1;
          font-size: 34px;
          color: $deep-sea-blue;
          padding-bottom: 5px;
        }
        .explanation {
          font-weight: bold;
          font-size: 12px;
        }
      }
      .youthbox {
        background-color: rgba(0, 167, 80, 0.16);
      }
      .adultsbox {
        background-color: rgba(238, 51, 78, 0.18);
      }
      .probox {
        background-color: rgba(0, 79, 158, 0.18);
      }
    }
  }

  .sports {
    margin-top: 50px;
    .topic {
      font-weight: bolder;
      margin-bottom: 30px;
      text-align: center;
    }
    .grid {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .sportbox {
        &:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
        cursor: pointer;
        order: 1;
        min-width: 150px;
        height: 190px;
        flex: 1 1 15%;
        margin: 20px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 15%;
        .name {
          margin-top: 5px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          max-width: 110px;
          height: 32px;
        }
        .amount {
          padding-top: 5px;
          font-size: 30px;
          text-align: center;
        }
      }
      .boxopen {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
        max-width: initial;
        order: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: width 0s, height 0.5s;
        background-color: #fbfbfb;
        width: 100%;
        flex: 1 0 100%;
        height: 400px;
      }
    }
  }
  .join {
    margin: 50px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    color: white;
    background-image: url("../../images/landings/landing3.jpg");
    background-size: cover;
    h1 {
      text-align: center;
      margin: 20px;
    }
    .btn {
      background-color: rgb(1, 118, 168);
      margin: 20px 10px;
      width: 300px !important;
      overflow-y: visible;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .joinbuttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .instructionscontainer {
      max-width: 1000px;
      padding: 10px;
      background: white;
      opacity: 0.75;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin: 20px 0;
      .subtitle {
        opacity: 1;
        color: $deep-sea-blue;
        font-size: 16px;
      }
      ul {
        width: 100%;
        padding: 0;
        margin: 10px;
        list-style: none;
        color: $deep-sea-blue;
        li {
          width: 100%;
        }
      }
    }
  }
  .benefits {
    margin: 50px 0 100px 0;
    width: 100%;
    .topic {
      width: 100%;
      text-align: center;
      font-weight: bold;
      margin-bottom: 60px;
    }
    .boxes {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 100%;
      & > div {
        min-width: 150px;
        flex: 1 1 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          max-height: 180px;
          margin-bottom: 20px;
        }
        .text {
          text-align: center;
          width: 80%;
        }
      }
    }
  }
  @media only screen and (max-width: 740px) {
    .smallkpi {
      display: flex;
      flex-direction: column !important;
      justify-content: flex-start;
      align-items: center;
      .box {
        flex: 1 0 100%;
        width: 90%;
        max-width: 500px;
        .title {
          height: 20%;
          min-height: 50px !important;
        }
      }
    }
    .benefits {
      .boxes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        & > div {
          margin-top: 20px;
        }
      }
    }
    .joinbuttons {
      flex-direction: column-reverse !important;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
