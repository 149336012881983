@import "../../styles/colors";

.organization-clubs-page-container {
  padding-top: 50px;
  padding-bottom: 80px;
  padding-left: 10px;

  .clubs-page-heading-container {
    padding-bottom: 50px;
    .clubs-page-heading-container-federation-name {
      width: 100%;
      text-transform: uppercase;
      font-size: 12px;
      color: $secondary-color;
    }
    .clubs-page-help-text {
      font-size: 14px;
      color: $greyish-brown;
    }
  }
  .search-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .bar {
      font-size: 12px;
      line-height: 32px;
      margin-right: 20px;
    }
    margin-bottom: 30px;
    .searchbar {
      input {
        border-radius: 3px;
        padding: 5px;
        border: 1px solid darkgray;
        background: url("../../images/Vector_search_icon.svg") no-repeat 98% 50%;
        width: 300px;
        height: 36px;
      }
    }
  }
}
