@import "../../styles/colors";

.expanding-content-row-header-container {
  width: 95%;

  .expandingContentRowHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 43px;
    background-color: #f1fafa;
    height: 74px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-top: 30px;

    &.archived {
      background-color: #f4f4f4;
    }

    &.topsports {
      background-color: #e6f4fb;
    }

    &.first {
      margin-top: 0;
    }

    .titletext {
      min-width: 350px;
    }

    .subtitletext {
      text-align: right;
    }

    .hidebutton {
      width: 32px;
      height: 32px;
      border-radius: 30px;
      background-color: #29a1d5;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 45px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.15);
      }
      img {
        margin-left: 1px;
      }
    }

    h4 {
      color: $greyish-brown;
      margin: 0;
      font-size: 19px;
      font-weight: bold;
    }
    .rightgroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 15px;
        line-height: 22px;
        color: $greyish-brown;
        margin-right: 20px;
      }
    }
  }
}

.sharedGroup {
  background-color: #e5e8e8 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
