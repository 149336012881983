@import "colors";
@import "mixins";

.formwidth {
  padding: 5px;
  @include screen-xl {
    width: 72%;
  }
  @include screen-lg {
    width: 80%;
  }
  @include screen-md {
    width: 90%;
  }
  @include screen-xs {
    width: 100%;
  }
  @include screen-sm {
    width: 100%;
  }
}

label {
  color: $dark-sky-blue-two;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.control-label,
.formlabel {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $greyish-brown;
  margin-bottom: 5px;
}

.nofinnishssnlink {
  @include link();
}

.label-blue {
  font-size: 16px;
  font-weight: 600;
  color: $deep-sea-blue;
}

hr {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-section {
  hr {
    border-color: #bae9e8;
    min-height: 1px;
  }
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 43px;
  border-left: 1px solid #dff4fa;
  border-right: 1px solid #dff4fa;
  border-bottom: 1px solid #dff4fa;
  @include screen-xs {
    padding: 0 13px;
  }
  @include screen-sm {
    padding: 0 13px;
  }

  > .form-group {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0;
  }
}

.form-group {
  flex-wrap: wrap;
}

.gradeselect {
  flex: 1 !important;
  width: 100%;
}

.fieldlabel {
  display: flex;
  flex-direction: column;
  flex: 0 1 35%;
  @include screen-sm {
    flex: 0 1 100%;
    height: initial;
  }
  @include screen-xs {
    flex: 0 1 100%;
    height: initial;
  }
}

.fieldoptionallabel {
  margin-left: 10px;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.3px;
  color: $warm-grey;
}

.formfield {
  flex: 0 1 60%;
  flex-grow: 1;
}

.formfielddescription {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: 0.3px;
  color: $greyish-brown;
  white-space: pre-line;
}

.checkboxfieldlabel {
  @extend .formfielddescription;
  margin-left: 10px;
}

// For bootstrap checkbox component
.checkbox-label {
  padding-left: 10px;

  > label {
    font-size: 13px;
    line-height: 1.23;
    letter-spacing: 0.3px;
    color: $greyish-brown;

    input {
      margin-right: 10px;
    }
  }
}

.timeselectionsubfield {
  display: flex;
  align-items: center;

  .formlabel {
    flex: 0 1 25%;
    margin: 0;
  }

  .form-group {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 0px;
  }

  .formfielddescription {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

.numbersubfield {
  display: flex;
  align-items: center;

  label {
    margin: 0 15px 0 0;
  }

  .formfielddescription {
    margin: 5px 10px 5px 10px;
  }

  .ageinput {
    border-radius: 2px;
    border: solid 1px #898989;
    box-shadow: none;

    &[disabled] {
      background-color: #f0f0f0;
      color: #808080;
    }
  }
}

.upgraderulecurrencyfield {
  .formfield {
    flex: 0 1 100%;
  }
}

.tagarrayfield {
  margin-bottom: 0;

  .formfield {
    flex-direction: row;
  }
}

.tagfield {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  min-width: 130px;
  margin: 3px;
  background-color: $dark-sky-blue;
  opacity: 0.9;
  text-align: center;
  color: #fff;
  border-radius: 100px;

  > .text {
    flex: 1 1 auto;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.8px;
    margin: 0 10px;
    padding: 2px 0;
    max-width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > .close-img {
    width: 20px;
    height: 21px;
    opacity: 0.92;
    background-color: #fff;
    border-radius: 100px;
    cursor: pointer;
    margin-right: 5px;
    margin-top: 0px;

    &:hover {
      img {
        transition: all 0.3s ease-in-out;
        transform: scale(1.05);
      }
    }
  }
}

.daterangefield {
  .formfield {
    flex-direction: row;
  }

  .daterangeseparator {
    height: 34px;
    line-height: 34px;
    margin: 0 10px;
    color: $warm-grey;
  }
}

.inlinecurrencyfield {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  max-width: 120px;
  span {
    margin-bottom: 10px;
  }
}

.currencyfield {
  max-width: 80px;
  margin-right: 5px;
}

.datesubfield {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0;

  .selector {
    display: flex;
    align-items: center;
    min-height: 24px;
    margin-bottom: 10px;

    .form-group {
      flex: 0 1 33%;
      margin: 0;
    }

    .subfield {
      flex: 0 1 30%;
      margin-bottom: 0;
    }
  }

  .datepickersubfield {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .subfield {
      flex: 0 1 30%;
      margin-bottom: 0;
    }
  }
}

.modal-form {
  button {
    width: 100%;
  }
}

.modal-label {
  @include form-field-label();
  width: 100%;
}

.modal-input-wrapper {
  position: relative;

  .extracontent {
    top: calc(52.5px - 50%);
  }
}

.modal-input-group {
  position: relative;
  margin-bottom: 25px;
}

.modal-input {
  height: 60px;
  font-size: 20px;
  border: 1px solid $warm-grey;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  outline: 0;

  .label-first {
    border-right: solid 1px #ccd2d2;
  }

  // For RadioButtonSubField styling where label is acting as a selector
  label {
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }

  &[disabled] {
    background-color: #f0f0f0;
    color: #898989;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  @include placeholder($warm-grey, normal, italic, 20px);
}

.register-select-wrapper {
  font-size: 18px;
  border-radius: 2px;
  outline: 0;

  select {
    width: 100px;
    height: 60px;
  }
}

.modal-error {
  color: $error-color;
}

.ageinput {
  height: 28px;
  width: 56px;
  outline: 0;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.radio.disabled {
  label {
    color: #808080;
    cursor: not-allowed;
  }
}

.checkbox.disabled {
  label {
    color: #808080;
    cursor: not-allowed;
  }
}

.formplaceholder {
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.3px;
}

.form-control {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.3px;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.3px;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.3px;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.3px;
  }

  &::-ms-input-placeholder {
    /* Edge */
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.3px;
  }

  &[disabled] {
    background-color: #f0f0f0;
    color: #808080;
  }
}

.has-success {
  .help-block {
    display: flex;
    flex-direction: column;
    color: $deep-sea-blue !important;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .form-control {
    border-radius: 2px;
    border: solid 1px $warm-grey;
    box-shadow: none;

    &:focus {
      border: solid 1px $warm-grey;
      background-color: rgba(121, 200, 206, 0.1);
      box-shadow: none;
    }
  }
}

.has-error {
  .help-block {
    display: flex;
    flex-direction: column;
    color: #c1392b !important;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-control {
    border-radius: 2px;
    border: solid 1px $warm-grey;
    box-shadow: none;

    &:focus {
      border: solid 1px $warm-grey;
      background-color: rgba(121, 200, 206, 0.1);
      box-shadow: none;
    }
  }
}

.input-group-addon:last-child {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-right: solid 1px #ccd2d2;
  border-top: solid 1px #ccd2d2;
  border-bottom: solid 1px #ccd2d2;
}

.ssndetails {
  margin-top: 15px;
}

.select-arrow {
  width: 16px;
  height: 9.6px;
  transform: rotate(180deg);
  border-radius: 0.1px;
  border: solid 1px $warm-grey;
}

.extracontent {
  position: absolute;
  top: 44%;
  right: 12px;

  .confirmed {
    @include paragraph-italic();
  }

  a {
    @include link();
  }

  span {
    color: $orange-two;
  }
}

.dependantnophonecontainer,
.dependantnoemailcontainer {
  position: absolute;
  top: -35px;
  right: 0px;

  .checkbox {
    label {
      color: $deep-sea-blue;
      font-size: 0.75em;
      line-height: 20px;
    }
  }
}

.labeladditional {
  float: right;
  margin: 1px 0 0 0;
  font-size: 10px;
}

.underline {
  text-decoration: underline;
}

.addnewrowlink {
  display: flex;
  padding-bottom: 10px;

  a {
    cursor: pointer;
  }
}

.autocompletefieldcontainer,
.deletableinputfieldcontainer {
  display: flex;
  flex-direction: column;

  &row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 70px;

    .form-group {
      flex: 1 1 auto;
    }
  }

  .removeicon {
    display: flex;
    align-items: center;
    height: 37px;
    margin-left: 10px;

    span {
      padding-right: 5px;
      line-height: 22px;
    }

    img {
      cursor: pointer;
    }
  }

  .deletableinputremoveicon {
    position: relative;

    img {
      cursor: pointer;
      height: 30px;
      margin-left: -42px;
      margin-top: 12px;
    }
  }
}

.manualinsurancecomponent {
  min-width: 1000px;

  .manualinsuranceheader {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .actionbtncontainer {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .actionbtn {
    width: 100%;
    margin-top: 0px;

    background-color: $orange;
    text-transform: uppercase;
    color: #fff;
    border: none;
    outline: 0 !important;
    border-radius: 50px;
    letter-spacing: 0.5px;

    &:hover {
      color: #fff;
      background-color: lighten($orange, 10%);
    }

    &:active:focus {
      color: #fff;
      background-color: lighten($orange, 10%);
    }

    &:active {
      color: #fff;
      background-color: lighten($orange, 10%);
    }

    &:focus {
      color: #fff;
      background-color: lighten($orange, 10%);
    }

    &[disabled] {
      opacity: 0.35;

      &:hover {
        background-color: $orange;
      }

      &:active:hover {
        color: #fff;
      }
    }
  }

  .actionbtn-lg {
    @extend .actionbtn;

    padding: 13px 20px;
  }

  .actionbtn-xs {
    @extend .actionbtn;

    padding: 7px 15px;
  }
}

.regular-radio-choice {
  .options {
    list-style: none;
    padding: 0;
  }
  input[type="radio"] {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;

    & + label {
      color: $greyish-brown;
      font-size: 13px;
      font-weight: normal;
      display: inline-block;
      margin-left: -2em;
      line-height: 1.5em;

      & > span {
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        margin: 0.25em 0.5em 0.1em 0.25em;
        border: 1px solid $greyish-brown;
        border-radius: 100px;
        vertical-align: bottom;
      }
    }
    &:checked + label > span > span {
      display: block;
      width: 0.85em;
      height: 0.85em;
      margin: 0.15em;
      border-radius: 100px;
      background-color: $dark-sky-blue;
    }
  }
}

.selectrange {
  display: flex;
  flex-direction: row;
  .selectfield {
    min-width: 140px;
    margin-right: 20px;
  }
}

/* Customize the label (the container) */
.checkbox-field-item {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    display: none !important;
  }

  label {
    margin-top: -5px;
  }
}

/* Hide the browser's default checkbox */
.checkbox-field-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(245, 245, 245);
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkbox-field-item:hover input ~ .checkmark {
  background-color: rgb(223, 223, 223);
}

/* When the checkbox is checked, add a blue background */
.checkbox-field-item input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-field-item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-field-item .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arraypushlink {
  color: $deep-sea-blue;
  margin-left: 10px;
  &:hover {
    text-decoration: none;
  }
}

.arraypush {
  margin: 10px;
  height: 40px;
  border-radius: 5px;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: $light-blue;
  }
  align-items: center;
  .arraypushlink {
    margin: 0;
    &:hover {
      text-decoration: none;
    }
  }
}

.arrayformsection {
  animation: fadein 0.5s;
  width: 100%;
}

.apdivider {
  margin: 0px -43px 0px -43px;
  padding: 20px 43px 20px 43px;
  background-color: rgba(223, 244, 250, 0.4);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-sublabel {
  font-size: 12px;
}

.suggestionitem {
  padding: 6px;
  cursor: default;

  &:hover {
    background-color: rgba(0, 85, 139, 0.1);
  }

  .suggestioniteminner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .suggestiontitle {
    font-size: 16px;
    text-align: left;
    color: $greyish-brown;
    margin-bottom: 4px;

    .priceineur {
      margin-right: 10px;
    }
  }

  .suggestionsubtitle {
    font-size: 12px;
    font-style: italic;
    text-align: left;
    color: $warm-grey;
    padding: 3px 0;
    margin-bottom: 4px;
    height: 16px;
  }
}

.suggestioncategory {
  font-size: 12px;
  text-transform: uppercase;
  margin: 20px 0 10px 7px;
  font-weight: bold;
  cursor: default;
}

.arraycontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  a {
    margin-top: 15px;
  }
}

.orgarrayhelp {
  padding-top: 15px;
}

.formcenter {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  a {
    margin-top: 20px;
  }
}

.stick-to-bottom {
  top: initial !important;
  bottom: 0;
}

.pathpart {
  .buttons {
    max-width: 1200px;
    position: relative;
    bottom: 0;
    background-color: white;
    padding-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    button {
      width: 29%;
      margin: 10px;
    }
    @include screen-xs {
      .centergroup {
        flex-direction: column;
      }
    }
    @include screen-sm {
      .centergroup {
        flex-direction: column;
      }
    }
    .centergroup {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1200px;
    }
  }
  .floatbuttons {
    position: fixed !important;
    bottom: 0;
    z-index: 120;
    padding-top: 0px;
    justify-content: center;

    width: calc(
      100% - 10px
    ); // -10px to mimic viewport-children left- and right-padding of 5px
  }
  .floatwidth {
    // limited by max-width when audit notes displayed as floating on large displays
    width: calc(
      100% - 360px
    ); // 360px = 330px (path-notes width) + 20px (path-notes margin) + 2*5px (viewport-children padding)

    @include screen-xs {
      width: calc(
        100% - 10px
      ); // -10px to mimic viewport-children left- and right-padding of 5px
    }
    @include screen-sm {
      width: calc(
        100% - 10px
      ); // -10px to mimic viewport-children left- and right-padding of 5px
    }
  }
  @include screen-xs {
    .buttons {
      flex-direction: column;
      button {
        width: 80%;
      }
    }
  }
  @include screen-sm {
    .buttons {
      flex-direction: column;
      button {
        width: 80%;
      }
    }
  }
  .partstatecontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 10px 0px;
    .stateboxstatus {
      padding-left: 10px;
      margin-right: 10px;
    }
    .stateindicator {
      width: 26px;
      height: 26px;
      border-radius: 30px;
      padding: 10px;
    }
    .statebox {
      padding: 8px 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 250px;
    }
    .stateReady {
      border: 1px solid #00a652;
      background-color: #00a652;
      color: white;
    }
    .stateDraft {
      border: 1px solid #00a652;
      background-color: white;
      color: #00a652;
    }
    .stateNot {
      border: 1px solid lightgrey;
      background-color: lightgrey;
    }
    .stateAwaiting {
      background-color: #00a652;
      border: 2px solid #faa61a;
    }
  }
}

.wideformfield {
  .formfield {
    max-width: 100% !important;
  }
}

.widefieldlabel {
  font-weight: bold;
  font-size: 16px;
}

.pathdropdown {
  .fieldlabel {
    flex: 0 1 60%;
    @include screen-sm {
      padding-bottom: 10px;
      flex: 0 1 100%;
      height: initial;
    }
    @include screen-xs {
      padding-bottom: 10px;
      flex: 0 1 100%;
      height: initial;
    }
  }
  .formfield {
    flex: 0 1 35%;
    @include screen-sm {
      flex: 0 1 100%;
      height: initial;
    }
    @include screen-xs {
      flex: 0 1 100%;
      height: initial;
    }
  }
  padding: 20px 0 0px 0 !important;
}

.summaryfield {
  .fieldlabel {
    flex: 0 1 45%;
  }
  .formfield {
    flex: 0 1 50%;
    textarea {
      height: 150px;
    }
  }
}

.radiobuttongroup {
  .fieldlabel {
    flex: 0 1 45%;
  }
  .formfield {
    flex: 0 1 50%;
    textarea {
      height: 150px;
    }
  }
  .summaryfield {
    label {
      color: #4a4a4a !important;
    }
  }
}

.correctiondate {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 12px;
  .DayPickerInput {
    margin-top: 5px;
    input {
      height: 40px;
      padding: 10px;
    }
  }
}

textarea.form-control {
  height: 180px;
}

.nocommentshelp {
  color: #faa61a;
  text-align: center;
  padding: 10px;
}

.improvementslist {
  padding: 20px 0 0;

  display: flex;
  flex-direction: column;

  .wideformfield {
    flex-direction: row;
    display: flex;
    margin-left: 20px;

    .fieldlabel {
      flex: unset;
      margin-right: 5px;
      justify-content: center;

      .formlabel {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}
