@import "src/styles/colors";

.filter-modal {
  width: 300px;
  filter: drop-shadow(0 2px 4px #b2b2b2);
  z-index: 200;
  background-color: white;
  padding: 25px;
  border: 1px solid #b2b2b2;
  border-radius: 3px;

  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0 5px 0;
  }
  .subtitle {
    text-align: center;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h3 {
    text-align: left;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 5px;
  }

  .arrayselectioncheckboxgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .checkbox {
      margin: 5px 5px 0 0;
      height: 20px;
      border-style: solid;
      border-width: 1px;
      border-color: #29a1d5;
      border-radius: 2px;

      input {
        opacity: 0;
        position: fixed;
        width: 0;
        z-index: -1000;
      }

      label {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;

        .labelcontainer {
          margin: 0 5px 0 5px;
          font-size: 11px;
          min-height: revert;
          text-align: center;
          text-transform: none;
          border: none;
          color: #29a1d5;
        }
      }
    }
    .unchecked {
      background: white;
    }
    .checked {
      background: #29a1d5;
      label > .labelcontainer {
        color: white;
      }
    }
  }

  .Select-menu-outer {
    z-index: 10000;
  }

  .Select {
    font-size: 12px;
    margin: 10px 0 5px 0;

    .Select-control {
      border: 1px solid #4a4a4a;
      border-radius: 2px;
    }
  }

  .hidelocked-checkbox {
    line-height: 18px;

    input {
      width: 18px;
      height: 18px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: -25px;
      filter: grayscale(1);
    }

    label {
      padding-left: 25px;
    }
  }

  .close-button {
    width: 100%;
    height: 40px;
    min-height: revert;
    line-height: revert !important;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear-button {
    width: 100%;
    min-height: revert;
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $deep-sea-blue;
  }
}

.filter-modal::before {
  border-bottom: 12px solid #b2b2b2;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -12px;
  right: 50px;
  margin-left: -10px;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.filter-modal::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -11px;
  right: 52px;
  margin-left: -10px;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 11px;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.filter-button {
  width: auto;
  color: $deep-sea-blue;
  float: right;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: revert;

  .filter-icon {
    margin-right: 5px;
    position: relative;

    .number-overlay {
      position: absolute;
      right: -5px;
      top: -10px;
      width: 20px;
      height: 20px;
      color: white;
      background-color: #29a1d5;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;
    }
  }
}
