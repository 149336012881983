.ratinglist {
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
  ul {
    margin-top: 6px;
    padding: 0;
    list-style-type: none;
  }
}
