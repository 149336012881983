@import "colors";

/**
* Put additional button styles here
**/

@mixin button-rounded($radius, $letterspacing) {
  border-radius: $radius;
  letter-spacing: $letterspacing;
}

.btn-increment {
  border: solid 1px #29a1d5;
  width: 28px;
  height: 28px;
  color: #4a4a4a;
  font-weight: 600;
  outline: 0;

  &:first-of-type {
    margin-right: 5px;
  }

  &[disabled]:hover {
    border-color: #4a4a4a;
  }

  &[disabled] {
    border-color: #4a4a4a;
    cursor: not-allowed;
  }
}

.btn-secondary-blue {
  background-color: $dark-sky-blue-two;
  font-weight: normal;
  font-size: 15px;
  color: #fff;
  border: none;
  outline: 0 !important;
  padding: 3px 10px;
  min-height: 55px;
  white-space: initial;
  line-height: 55px !important;
  @include button-rounded(50px, 0.5px);

  &:hover {
    color: #fff;
    background-color: lighten($dark-sky-blue-two, 10%);
  }

  &:hover:active {
    color: #fff;
    background-color: lighten($dark-sky-blue-two, 10%);
  }

  &:active:focus {
    color: #fff;
    background-color: lighten($dark-sky-blue-two, 10%);
  }

  &:active {
    color: #fff;
    background-color: lighten($dark-sky-blue-two, 10%);
  }

  &:focus {
    color: #fff;
    background-color: lighten($dark-sky-blue-two, 10%);
  }

  &[disabled],
  &.disabled {
    background-color: $dark-sky-blue-two;
    opacity: 0.35;
    cursor: not-allowed;

    &:hover {
      background-color: $dark-sky-blue-two;
    }

    &:focus {
      color: #fff;
      background-color: lighten($dark-sky-blue-two, 10%);
    }
  }
}

.btn-secondary-dark-blue {
  background-color: $deep-sea-blue;
  font-weight: normal;
  text-transform: uppercase;
  color: #fff;
  border: none;
  outline: 0 !important;
  padding: 3px 10px;
  min-height: 55px;
  white-space: initial;
  line-height: initial !important;
  @include button-rounded(50px, 0.5px);

  &:hover {
    color: #fff;
    background-color: lighten($deep-sea-blue, 10%);
  }

  &:active:focus {
    color: #fff;
    background-color: lighten($deep-sea-blue, 10%);
  }

  &:active {
    color: #fff;
    background-color: lighten($deep-sea-blue, 10%);
  }

  &:focus {
    color: #fff;
    background-color: lighten($deep-sea-blue, 10%);
  }

  &[disabled] {
    opacity: 0.35;

    &:hover {
      background-color: $deep-sea-blue;
    }
  }
}

.btn-transparent {
  width: 150px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid #fff;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-align: center;
  font-weight: normal;
  padding: 3px 10px;
  min-height: 55px;
  white-space: initial;
  line-height: initial !important;
  outline: 0;
  color: #fff;

  &:hover {
    background-color: rgba(256, 256, 256, 0.1);
  }

  &:active {
    background-color: rgba(256, 256, 256, 0.33);
  }

  &:focus {
    background-color: rgba(256, 256, 256, 0.33);
  }

  &[disabled] {
    opacity: 0.35;
  }
}

.btn-invisible {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none !important;
  font-size: 0;
  outline: none;
  &:focus {
    outline: 2px auto;
  }
}

.btn-green {
  width: 150px;
  border-radius: 100px;
  border: 1px solid #f9f9f9;
  background-color: rgba(35, 190, 184, 0.75);
  font-size: 13px;
  letter-spacing: 0.3px;
  text-align: center;
  font-weight: bold;
  padding: 8px 10px;
  outline: 0;
  color: #fff;

  &:hover {
    background-color: rgba(35, 190, 184, 0.33);
  }

  &:active {
    background-color: rgba(35, 190, 184, 0.53);
  }

  &:focus {
    background-color: rgba(35, 190, 184, 0.53);
  }

  &[disabled] {
    opacity: 0.35;
  }
}

.btn-small {
  width: 120px;
}

.btn-full {
  width: 100%;
}

.btn-disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
