@import "../styles/colors";

.infobox {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  span {
    align-self: flex-start;
    svg {
      height: 20px;
      width: 20px !important;
    }
  }
  p {
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    color: $greyish-brown;
  }
}
