@import "../../styles/mixins";
@import "../../styles/colors";
.pathnotes {
  height: calc(100% - 310px - 260px) !important;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  margin-left: 20px;
  margin-right: 0;
  flex: 0 0 330px;
  z-index: 124;
  min-width: 330px;
  width: 330px;
  color: $greyish-brown;
  .shadowdiv {
    box-shadow: -10px 0px 10px -10px rgba(184, 184, 184, 1);
    width: 30px;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 10;
  }
  @include screen-xl2 {
    position: absolute;
    align-self: flex-start;
    right: 0;
    width: calc((100% - 1200px) / 2 - 20px);
    max-width: 610px;
    margin-right: 5px;
  }
  @include screen-sm {
    position: absolute;
    right: 0;
    width: 330px;
    margin-right: 5px;
  }
  @include screen-xs {
    position: absolute;
    right: 0;
    width: 330px;
    margin-right: 5px;
  }
  h2 {
    align-self: flex-start;
    margin-top: 60px !important;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }
  h3 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 22px;
  }
  textarea {
    width: 100%;
    height: 25em; // mustn't use size relative to view height, because it can cause an infinite scroll event loop in some cases
    min-height: 4em;
    resize: vertical;
    padding: 10px;
    border: 1px solid $greyish-brown;
    border-radius: 3px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .closebutton {
    border: none;
    background: none;
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 0;
    outline: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      padding: 5px;
      width: 55px;
      height: 55px;
    }
  }
  .relcontainer {
    position: relative;
  }
  .fixedcontainer {
    position: fixed;
    top: 50px; // height of nav bar
  }
  .notecontainer {
    background-color: white;
    padding: 20px 30px 30px 30px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    width: 320px;
    min-width: 320px;
    @include screen-xl2 {
      width: calc((100vw - 1200px) / 2 - 30px);
      max-width: 600px;
    }
    & > * {
      width: 100%;
    }
  }
  .savebutton {
    margin-top: 20px;
    width: 90%;
    align-self: center;
  }
}
.reverse {
  justify-content: flex-end !important;
  align-self: flex-end;
  .fixedcontainer {
    position: inherit;
  }
}

.notesbutton {
  img {
    padding: 5px;
    width: 55px;
    height: 55px;
  }
  filter: drop-shadow(0px 0px 4px #797979);
  position: absolute;
  right: 15px;
  border: none;
  background: none;
  padding: 0;
  outline: none;
}

.floatbutton {
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 123;
}

.alertcontainer {
  margin: 20px 0;
  height: 1em;

  font-weight: bold;
  .success {
    color: #00a652;
  }
  .failure {
    color: $lipstick;
  }
}
