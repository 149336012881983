/**
 * FixedDataTable v0.8.10 
 *
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTable
 *
 */

/**
 * Table.
 */
.public_fixedDataTable_main {
  border-radius: 6px 6px 8px 8px;
  overflow: hidden;
  outline: 0;
  div {
    border: 0;
  }
}

.fixedDataTableCellGroupLayout_cellGroup {
  .public_fixedDataTableCell_main {
    cursor: pointer;
    transition: 0.4s;
  }
  &:hover {
    .public_fixedDataTableCell_main {
      background-color: #f5f5f5 !important;
    }
  }
}

.public_fixedDataTable_header,
.public_fixedDataTable_hasBottomBorder {
  border-color: #d3d3d3;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  font-weight: bold;
}

.public_fixedDataTable_header {
  color: white;
}

.jobqueuetable {
  border: 0;
  outline: 0;
}

.queuetableheader {
  background-color: #29a1d5;
  font-weight: normal;
}

.public_fixedDataTable_header,
.public_fixedDataTable_scrollbarSpacer,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: #29a1d5;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#efefef)
  );
  background-image: linear-gradient(#fff, #efefef);
}

.public_fixedDataTable_scrollbarSpacer {
  position: absolute;
  z-index: 99;
}

.public_fixedDataTable_footer .public_fixedDataTableCell_main {
  background-color: #f6f7f8;
}

.public_fixedDataTable_topShadow {
  background: 0 0
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=)
    repeat-x;
}

.public_fixedDataTable_bottomShadow {
  background: 0 0
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==)
    repeat-x;
}

.public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
  background-color: #fff;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCell
 */

/**
 * Table cell.
 */
.public_fixedDataTableCell_main {
  background-color: #fff;
}

.public_fixedDataTableCell_highlighted {
  background-color: #f4f4f4;
}

.public_fixedDataTableCell_cellContent {
  padding: 8px;
}

.public_fixedDataTableCell_columnResizerKnob {
  background-color: #0284ff;
}
.public_fixedDataTableCell_hasReorderHandle
  .public_fixedDataTableCell_cellContent {
  margin-left: 12px;
}
/**
 * Column reorder goodies.
 */
.fixedDataTableCellLayout_columnReorderContainer {
  border-color: #0284ff;
  background-color: rgba(0, 0, 0, 0.1);
  width: 12px;
  margin-right: -12px;
  float: left;
  cursor: move;
}
.fixedDataTableCellLayout_columnReorderContainer:after {
  content: "::";
  position: absolute;
  top: 50%;
  left: 1px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLine
 *
 */

/**
 * Column resizer line.
 */
.public_fixedDataTableColumnResizerLine_main {
  border-color: #0284ff;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRow
 */

/**
 * Table row.
 */
.public_fixedDataTableRow_main {
  background-color: #fff;
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: #f1fafa;
}

.public_fixedDataTableRow_fixedColumnsDivider {
}

.public_fixedDataTableRow_columnsShadow {
  background: 0 0
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==)
    repeat-y;
}

.public_fixedDataTableRow_columnsRightShadow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule Scrollbar
 *
 */

/**
 * Scrollbars.
 */

/* Touching the scroll-track directly makes the scroll-track bolder */
.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main {
  background-color: #fff;
  border-left: 1px solid #d3d3d3;
}

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
  background-color: #fff;
}

.public_Scrollbar_face:after {
  background-color: #c2c2c2;
}

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
  background-color: #7d7d7d;
}
