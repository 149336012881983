@import "../../../styles/colors";

.partcontainer {
  & > hr {
    border: 1px solid $light-blue-grey;
  }

  & > h1 {
    color: $greyish-brown;
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.85;
    letter-spacing: normal;
    align-self: flex-start;
  }
}

.summaryinfo {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  .clubname {
    text-transform: uppercase;
    color: $secondary-color;
  }
  h1 {
    font-size: 36px;
    color: $greyish-brown;
    font-weight: bold;
  }
  h3 {
    margin: 0;
    font-size: 11px;
    line-height: 1.64;
  }
}

.top-sports-basiccriteria-section {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-bottom: 15px;
  h1 {
    color: $greyish-brown;
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.85;
    letter-spacing: normal;
    align-self: flex-start;
  }

  .top-sports-basiccriteria-text {
    font-size: 12px;
  }
}

.improvements {
  width: 100%;
  & > .formGroupHeaderContainer {
    .form-section {
      padding-bottom: 10px;
    }
  }
  .formGroupHeaderContainer {
    .form-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0;

      .improvementsectioncontent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: center;
        flex-direction: column;
        width: 95%;
        color: $greyish-brown;
        font-size: 19px;
        line-height: 22px;
        height: 100%;
        font-weight: bold;
      }

      & > .formGroupHeaderContainer {
        width: 97%;
        padding-right: 8px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        .improvementline {
          width: 100%;
          min-height: 80px;

          padding: 0 20px 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          border-bottom: 1px solid $light-light-blue;

          .improvementcontent {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            color: $greyish-brown;
            font-size: 19px;
            line-height: 22px;
            height: 100%;
            font-weight: bold;
          }
        }
        .improvementline:last-child {
          border-bottom: none;
          margin-bottom: -10px;
        }
      }
    }
    .expandingContentRowHeader {
      margin-top: 10px;
      width: 100%;
    }
  }
  margin-bottom: 20px;
}
