@import "./../../../styles/mixins";

.commentfield {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @include screen-xs {
    justify-content: flex-start;
  }
  @include screen-sm {
    justify-content: flex-start;
  }

  .usercomment {
    width: 100%;
    position: relative;
    p {
      margin-right: 25px;
      margin-bottom: 0px;
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      align-self: flex-end;
    }
    color: #ff7500;
  }
}

.auditfield {
  width: initial;
  .addcomment {
    width: initial;
    font-weight: bold;
    color: $deep-sea-blue;
    position: relative;
    img {
      padding-left: 5px;
      position: absolute;
      right: 5px;
    }
    padding-right: 30px !important;
    text-align: right;
  }
}

.hoverborder {
  padding: 5px 5px 5px 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.bottomright {
  padding-left: 5px;
  position: absolute;
  bottom: 5px;
  right: 18px;
}

.hoverborder:hover {
  border: 1px solid lightgrey;
}

.commentcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  position: relative;
  flex: 0 1 35%;
  @include screen-xs {
    flex: 0 1 100%;
    justify-content: flex-start;
  }
  @include screen-sm {
    flex: 0 1 100%;
    justify-content: flex-start;
  }
  img {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .addcomment {
    @include screen-xs {
      align-self: flex-end;
    }
    @include screen-sm {
      align-self: flex-end;
    }
  }
  .text {
    width: 100%;
    color: $deep-sea-blue;
    padding-right: 25px;
    text-align: right;
  }
  .userdescription {
    flex: 1 1 auto;
    position: relative;
    width: 100%;

    p {
      margin-right: 25px;
      margin-bottom: 0px;
      text-align: left;
      width: inherit;
    }

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    img {
      align-self: flex-end;
    }
    color: #1e9ea8;
  }
}

.descriptionfield {
  flex: 0 1 35%;
  .addcomment {
    width: initial;
    font-weight: bold;
    color: $deep-sea-blue;
    position: relative;
    img {
      padding-left: 5px;
      position: absolute;
      right: 5px;
    }
    padding-right: 30px !important;
    text-align: right;
  }
}
.descriptioncontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  position: relative;
  flex: 0 1 100%;
  @include screen-xs {
    flex: 0 1 100%;
    justify-content: flex-start;
  }
  @include screen-sm {
    flex: 0 1 100%;
    justify-content: flex-start;
  }
  img {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .addcomment {
    @include screen-xs {
      align-self: flex-end;
    }
    @include screen-sm {
      align-self: flex-end;
    }
  }
  .text {
    width: 100%;
    color: $deep-sea-blue;
    padding-right: 25px;
    text-align: right;
  }
  .userdescription {
    flex: 1 1 auto;
    position: relative;
    width: 100%;

    p {
      margin-right: 25px;
      margin-bottom: 0px;
      text-align: left;
      width: inherit;
    }

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    img {
      align-self: flex-end;
    }
    color: #1e9ea8;
  }
}
