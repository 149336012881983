.club-development-intro-modal {
  width: 560px;
  margin-top: auto;
  margin-bottom: auto;

  .modal-content {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: white;
    width: 100%;
    border-radius: 5px;
  }

  .modal-body {
    width: 100%;
    padding: 40px 25px;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 30px;
      line-height: 32px;
      text-align: center;
      color: #00558b;
    }

    .intro-desc {
      margin: 20px 40px 20px 40px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #4a4a4a;
    }

    hr {
      margin: 20px 0;
    }

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #4a4a4a;
      margin: 20px 0 16px 0;
    }

    .intro-preface {
      font-size: 13px;
      line-height: 20px;
      color: #4a4a4a;
    }

    .step {
      margin: 20px 0 15px 0;
      display: flex;
      flex-direction: row;

      font-size: 13px;
      line-height: 20px;
      color: #4a4a4a;

      img {
        margin-right: 30px;
        align-self: flex-start;
      }

      h3 {
        margin: 5px 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #4a4a4a;
      }
    }

    .intro-postface {
      font-size: 13px;
      line-height: 20px;
      color: #4a4a4a;
      margin: 25px 0 0 0;
    }

    .close-button {
      width: 300px;
      height: 50px;
      min-height: revert;
      line-height: revert !important;
      margin-top: 40px;
      align-self: center;
    }
  }
}
