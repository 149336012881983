@import "../../styles/colors";

.auditingcontainer {
  min-height: 70vh;
  padding-top: 50px;
  padding-bottom: 50px;

  .orginfocontainer {
    padding: 10px 10px 50px 10px;

    .auditing-container-federation-name {
      width: 100%;
      text-transform: uppercase;
      font-size: 12px;
      color: $secondary-color;
    }

    .auditing-help-text {
      font-size: 14px;
      color: $greyish-brown;
    }
  }

  .infocontainer {
    padding: 0px 10px 10px 10px;
    h2 {
      color: $greyish-brown;
      font-weight: bold;
      font-size: 19px;
    }
  }

  .searchcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .bar {
      font-size: 12px;
      line-height: 32px;
      margin-right: 20px;
    }
    padding: 10px;
    margin-bottom: 30px;
    .searchbar {
      input {
        border-radius: 3px;
        padding: 5px;
        border: 1px solid darkgray;
        background: url("../../images/Vector_search_icon.svg") no-repeat 98% 50%;
        width: 300px;
        height: 36px;
      }
    }
    .filterbar {
      .Select {
        max-width: 300px;
        width: 100%;
        min-width: 200px;
      }
    }
    .buttonbar {
      margin-left: auto;
      a {
        min-width: 180px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media only screen and (max-width: 740px) {
    .searchcontainer {
      flex-direction: column;
    }
  }
}

.audit-submit-modal {
  .modal-content {
    background-color: #e5e5e5;
    width: 100%;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    .modal-body {
      h1 {
        color: $deep-sea-blue;
        margin-bottom: 40px;
        font-weight: 400;
      }
      h2 {
        font-size: 19px;
        color: $greyish-brown;
        font-weight: bold;
        margin-bottom: 30px;
      }
      hr {
        margin: 20px 0;
        border-color: #c2c2c2;
      }
      width: 100%;
      height: 100%;
    }
    .modal-footer {
      width: 100%;
    }
    .modalbuttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        width: 45%;
        max-width: 350px;
      }
    }
  }
}
