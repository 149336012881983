.development-item-card {
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > * {
      margin: 10px;
    }

    .importance {
      font-weight: bold;
      color: #29a1d5;
      margin-bottom: 5px;
      height: 1em;
    }
    .description {
      margin-top: auto;
      margin-bottom: auto;

      text-overflow: ellipsis;
      max-height: 100%;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .inforow {
      display: flex;
      flex-direction: row;

      .personicon {
        margin-right: 8px;
      }
      .not-assigned {
        opacity: 0.5;
      }

      .assignee {
        align-self: center;
        flex-grow: 1;

        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .statusrow {
        z-index: 10;
        margin-right: -25px;
        flex-shrink: 0;

        img {
          margin-left: 6px;
        }
      }
    }
  }
}
