.year-tab {
  height: 100%;
  width: 210px;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #bae9e8;
  border-bottom: 0;
  margin-left: 16px;
  display: grid;

  .background {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: calc(100% - 3px);
    margin-top: 5px;
  }

  .year-button {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    color: #4a4a4a;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    align-items: stretch;

    span {
      text-align: center;
    }

    .year {
      font-size: 21px;
    }
  }
}

.year-tab:first-child {
  margin-left: 0;
}

.year-tab-selected {
  z-index: 100;
  background: #f1fafa;

  .background {
    background: #f1fafa;
  }

  .year-button {
    .year {
      font-weight: bold;
    }
  }
}

.year-tab-dragover {
  background-color: #00b7c6;
  border-bottom: 1px solid white;

  .year-button {
    color: white;
  }
}

.year-tab-selected.year-tab-dragover {
  .background {
    background: #00b7c6;
  }
}

.year-tab-droppable {
  background-color: #bae9e8;
  border-bottom: 1px solid white;
}

.year-tab-selected.year-tab-droppable {
  .background {
    background: #bae9e8;
  }
}
